<template>
	<div>
		<LoadingScreen v-if="loading_screen" />
		<ContentHeader title="Setting Klinik" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
	              <div class="card-header">
	                <h3 class="card-title"><div style="#007bff">Setting Data Klinik</div></h3>
	              </div>
	              <div class="card-body row">
	              	<div class="col-lg-2"></div>
	              	<div class="col-lg-8">
	              		<form @submit.prevent="updateKlinik" enctype="multipart/form-data">
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nama">Nama Cabang</label>
			                    <input type="text" class="form-control" id="nama" name="nama" v-model="cabang.nama" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="sio">SIO (Surat Izin Operasi)</label>
			                    <input type="text" class="form-control" id="sio" name="sio" v-model="cabang.sio" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <label for="masa_awal_berlaku">Masa Berlaku SIO</label>
			                  <div class="row">
			                  	<div class="col">
			                  		<div class="form-group">
					                    <span for="awal_berlaku_sio">Awal Berlaku SIO</span>
					                    <input type="date" class="form-control" id="awal_berlaku_sio" name="awal_berlaku_sio" v-model="cabang.awal_berlaku_sio" autocomplete="off">
					                  </div>
			                  	</div>
			                  	<div class="col">
			                  		<div class="form-group">
					                    <span for="akhir_berlaku_sio">Akhir Berlaku SIO</span>
					                    <input type="date" class="form-control" id="akhir_berlaku_sio" name="akhir_berlaku_sio" v-model="cabang.akhir_berlaku_sio" autocomplete="off">
					                  </div>
			                  	</div>
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="npwp">NPWP</label>
			                    <input type="text" class="form-control" id="npwp" name="npwp" v-model="cabang.npwp" autocomplete="off">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="alamat">Alamat</label>
			                    <input type="text" class="form-control" id="alamat" name="alamat" v-model="cabang.alamat" autocomplete="off">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="telepon">Telepon</label>
			                    <input type="tel" class="form-control" id="telepon" name="telepon" v-model="cabang.telepon" autocomplete="off">
			                  </div>
			                </div>
			                <div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="nomor_handphone">Nomor Handphone</label>
			                    <input type="tel" class="form-control" id="nomor_handphone" name="nomor_handphone" v-model="cabang.nomor_handphone" autocomplete="off">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="penanggung_jawab">Penanggung Jawab</label>
			                    <input type="text" class="form-control" id="penanggung_jawab" name="penanggung_jawab" v-model="cabang.penanggung_jawab" autocomplete="off">
			                  </div>
			                </div>
		                	<div class="px-lg-2 ">
			                  <div class="form-group">
			                    <label for="file">Logo</label>
			                    <input type="file" class="form-control-file" id="file" name="file" @change="inputImage" autocomplete="off">
			                  </div>
			                </div>
			                <img :src="cabang.image" >
			                <div class="px-lg-2">
			                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
									<div>Submit</div>	
									<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
									  <span class="sr-only">Loading...</span>
									</div>
								</button>
			                </div>
		                </form>
	              	</div>
	              	<div class="col-lg-2"></div>
	              </div>
	              <!-- /.card-body -->
	              <div class="card-footer" style="display: block;">
	                
	              </div>
	              <!-- /.card-footer-->
            	</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import LoadingScreen from '@/components/LoadingScreen'

import { computed, ref, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import { objectToForm } from 'object-to-form'

export default{
	components: {
		ContentHeader,
		LoadingScreen
	},
	setup(){
		const user = computed(() => store.getters['auth/user'])
		const loading = ref(false)
		const loading_screen = ref(true)

		const cabang = ref([])
		// console.log(cabang)

		const getCabang = async () => {
			let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

			if (response.data != null) {
				loading_screen.value = false
				cabang.value = response.data
				cabang.value._method = 'PUT'
			}
		}

		const inputImage = (e) => {
			var files = e.target.files || e.dataTransfer.files;
			cabang.value.logo = files[0]
			createImage(files[0]);
		}

		const createImage = (file) => {
		    var reader = new FileReader();

		    reader.onload = (e) => {
		      cabang.value.image = e.target.result;
		    }

		    if (file) reader.readAsDataURL(file) 
		    
		}

		const updateKlinik = () => {
			loading.value = true
			console.log(cabang.value)
			axios.post(`api/cabang/update/${user.value.cabang_id}`, objectToForm(cabang.value))
			.then((response) => {
				Swal.fire({
				  title: 'Berhasil!',
				  text: 'Berhasil Update Data',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})
				loading.value = false
				getCabang()
				console.log(response)
			})
			.catch((error) => {
				Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal Update data',
				  icon: 'error',
				  showConfirmButton: false,
				  timer: 1500
				})	
				loading.value = false
				console.log(error)
			})
		}

		onMounted(() => {
			getCabang()
		})

		return {
			cabang,
			getCabang,
			updateKlinik,
			loading,
			loading_screen,
			inputImage
		}
	}
}
</script>

<style>
img {
  width: 30%;
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
}
</style>